let shop1 = [
    ["普通攻击", 10, 1, "攻击+10"],
    ["普通攻击", 10, 1, "攻击+10"],
    ["普通防御", 0, 1, "防御+10"],
    ["普通防御", 0, 1, "防御+10"],
    ["治疗回血", 0, 1, "回血+10"],
    ["治疗回血", 0, 1, "回血+10"],
    ["双重打击", 20, 1, "攻击+20"],
    ["致命一击", 0, 2, "使当前攻击力翻倍"],
    ["超级防御", 0, 2, "使当前防御翻倍"],
    ["完美防御", 0, 3, "完全抵挡所有伤害"],
    ["护盾打击", 0, 1, "护盾值也会作为伤害攻击"],
    ["危险进攻", 30, 2, "造成30伤害但自身也会受伤-5"],
    ["免费重置", 0, 0, "免费重置商店"],
    ["补充能量", 0, 0, "恢复一点能量"],
]

let power1 = [
    ["圣杯国王", "永久提升50血量"],
    ["圣杯皇后", "每回合提升5血量"],
    ["圣杯骑士", "当你濒死时,立即获得30血量"],
    ["圣杯侍从", "当你濒死时,不会死亡"],

    ["星币国王", "如果你当前低20金币,立即获得30金币"],
    ["星币皇后", "立即获得20金币"],
    ["星币骑士", "每回合多获得5金币"],
    ["星币侍从", "三回合后获得30金币"],

    ["宝剑国王", "回合结束手中没有攻击牌，则攻击力翻倍"],
    ["宝剑骑士", "每回合提升10攻击"],
    ["宝剑皇后", "你购买的攻牌一回合后属性翻倍,上限40"],
    ["宝剑侍从", "当你一回合打出三次攻击牌,攻击力+20"],

    ["权杖国王", "每回合多获得2能量"],
    ["权杖骑士", "每回合多获得10护盾"],
    ["权杖皇后", "每回合多获得一次免费商店刷新"],
    ["权杖侍从", "5回合后获得丰富强化"],
]

let thing1 = [
    ["愚人", "恢复双方100血量"],
    ["倒吊人", "减少双方30血量"],
    ["世界", "随机变化双方血量、攻击和防御"],
    ["命运之轮", "重新开始战斗"],
    ["死神", "直接结束对局,血量多着胜"],

    ["女祭司", "随机变化自身血量、攻击和防御"],
    ["力量", "永久提升20攻击力"],
    ["战车", "该回合提升30攻击力"],
    ["隐士", "该回合提升30护盾"],
    ["太阳", "恢复自身50血量"],
    ["月亮", "每回合增加自身1能量"],
    ["节制", "每回合减少自身1能量"],
    ["教皇", "该回合增加自身3能量"],
    ["女皇", "每回合增加自身5金币"],
    ["皇帝", "增加自身30金币"],
    ["恋人", "翻倍自身全属性"],
    ["审判", "该回合初始化三倍攻击"],
    ["灯塔", "该回合boss不能操作"],
    ["星星", "强化多获得一次刷新"],

    ["恶魔", "boss全属性翻倍"],
    ["魔术师", "随机变化boss血量、攻击和防御"],
]

let mbti1 = [
    ["ISTJ", "每回合多一能量"],
    ["ISFJ", "每回合多10攻击"],
    ["INFJ", "每回合多10护盾"],
    ["INTJ", "每回合恢复3血量"],
    ["ISTP", "每回合多3金币"],
    ["ISFP", "回合开始多20金币"],
    ["INFP", "强化多获得一次刷新"],
    ["INTP", "全属性+5"],
]

let xingzuo1 = [
    ["巨蟹座", "暂无强化"],
    ["双鱼座", "每回合减少你1能量"],
    ["金牛座", "每回合减少你3金币"],
    ["处女座", "每回合减少你10护盾"],
    ["天蝎座", "每回合多10攻击"],
]

let site1 = [
    ["列车站", ["保安", "售票员", "路人1"], ["保安室", "售票台"], [["等候厅", ["路人2", "路人3", "路人4"], ["服务台", "座位1", "座位2", "座位3"]], ["厕所", ["路人5"]]]],
    ["列车", ["列车员", "服务员", "路人1"], ["座位1", "座位2", "座位3"], [["车厢1", ["路人2", "路人3", "路人4"], ["座位4", "座位5", "座位6"]], ["车厢2", ["路人5", "路人6", "路人7"], ["座位7", "座位8", "座位9"]], ["车厢3", ["路人8", "路人9", "路人10"], ["座位10", "座位11", "座位12"]], ["厕所", ["路人11"]]]],
    ["学校", ["门卫", "社区工作者", "学生1"], ["保安室", "售票台"], [["食堂", ["教师1", "学生2", "学生3"]], ["操场", ["学生4", "学生5", "学生6"]], ["厕所", []]]],
    ["教学楼", ["门卫"], ["保安室", "售票台"], [["教室1", ["教师1", "学生2", "学生3"]], ["教室2", []], ["教室3", ["学生4"]], ["教室4", ["学生5"]], ["教室5", ["学生6"]], ["厕所", []]]],
    ["宿舍楼", ["宿管阿姨"], ["保安室", "售票台"], [["101", ["学生1", "学生2", "学生3"]], ["102", ["学生1", "学生2", "学生3"]], ["103", ["学生4"]], ["104", ["学生5"]], ["105", []], ["厕所", []]]],
    ["105宿舍", [], ["保安室", "售票台"], [["1号床", []], ["2号床", []], ["3号床", []], ["4号床", []], ["厕所", []]]],
    ["医院", ["保安", "护士1", "病人1"], ["保安室", "售票台"], [["门诊1", ["医生1", "病人2"]], ["门诊2", []], ["门诊3", ["医生2"]], ["门诊4", ["护士2"]], ["门诊5", ["病人3"]], ["厕所", []]]],
    ["教堂", ["神父", "信徒1", "信徒2"], ["保安室", "售票台"], [["储藏室", []], ["后室", []], ["阁楼", []], ["厕所", []]]],
]

let say1 = [
    ["列车站保安", ["你好,我是这里的保安,有什么事跟我说就行了"], "[你违反了规则]你往哪走啊?"],
    ["列车站售票员", ["你好,我是这里的售票员,你买好票了吗", "今日有去春市和冬市的票", "去夏市的车马上要出发了"], "[你违反了规则]你往哪走啊?"],
    ["列车站路人1", ["哦?我在这等车", "你也是去夏市的吗"], "[你违反了规则]你往哪走啊?"],
    ["列车站路人2", ["怎么了?", "我也不太清楚,要不你去服务台问问"], "[你违反了规则]你往哪走啊?"],
    ["列车站路人4", ["我知道你要找什么", "拿个东西跟我换"], "[你违反了规则]你往哪走啊?"],
    ["列车站路人5", ["你在厕所看到了路人5", "她走后", "发现她遗失了一枚戒指"], "[你违反了规则]你往哪走啊?"],
    ["列车路人1", ["我就知道你也是去夏市的", "哈哈哈我们路上也有伴了不是", "我们这么有缘,来给你一个好东西"], "[愤怒暴走]我让你拿着!"],
    ["列车服务员", ["这里堆放了很多上批乘客留下来的垃圾", "这收拾起来有些麻烦", "有人帮帮我吗"], "[你违反了规则]你往哪走啊?"],
    ["列车列车员", ["有事请找蓝色制服服务员", "他们很可靠"], "[你违反了规则]你往哪走啊?"],
    ["列车路人11", ["规则怎么找不到了", "部分规则已经被玷污了", "你有看到吗"], "[你违反了规则]你往哪走啊?"],
    ["列车路人2", ["太可怕了", "怎么会遇到这种事", "好害怕呀"], "[你违反了规则]你往哪走啊?"],
    ["列车路人3", ["对呀对呀", "我们只是睡了一觉就发生这种事", "真令人不安"], "[你违反了规则]你往哪走啊?"],
    ["列车路人4", ["还有多长事件到站", "马上到站吧", "待不下去了"], "[你违反了规则]你往哪走啊?"],
    ["列车路人5", ["他看起来不是正常死亡而是被杀", "但是没有伤口", "希望警察能调查清楚吧"], "[你违反了规则]你往哪走啊?"],
    ["列车路人6", ["我知道谁是凶手", "是车厢3的路人9"], "[你违反了规则]你往哪走啊?"],
    ["列车路人7", ["不关我事"], "[你违反了规则]你往哪走啊?"],
    ["列车路人8", ["车厢1的路人3,他在说谎"], "[你违反了规则]你往哪走啊?"],
    ["列车路人9", ["真的不是我啊", "我被人诬陷了,我在凌晨亲眼看到车厢1的路人4去你们车厢来着"], "[你违反了规则]你往哪走啊?"],
    ["列车路人10", ["我亲眼看到你给了路人1吃了东西", "你别想跑了"], "[你违反了规则]你往哪走啊?"],
]

let word1 = [
    ["列车站",
        [
            "1.等候厅是安全的,如有问题立刻前往",
            "2.保安是可信的",
        ]],
    ["列车",
        [
            "1.蓝色制服服务员是不可信的",
            "2.除了列车员,不要相信其他人",
            "3.尽量不要拒绝他人要求",
            "4.16:00之后不要随意更换车厢和座位",
        ]]
]

let thing2 = [
    ["愚人", "伪装成随机牌"],
    ["倒吊人", "失魂"],
    ["世界", "去掉规则中的一条"],
    ["命运之轮", "时间轮转,重新开始或者直接通过"],
    ["死神", "倒计时结束前完成即安全"],
    ["力量", "获得隐藏道具"],
    ["战车", "无事发生"],
    ["太阳", "时间轮转为白天"],
    ["月亮", "时间轮转为夜晚"],
    ["节制", "你已被禁止使用道具"],
    ["恋人", "随机变化时间"],
    ["审判", "免疫一次攻击"],
    ["灯塔", "暂时失去攻击欲望"],
    ["星星", "重新抽一次"],
    ["恶魔", "恶魔现身"],
]

let element1 = [
    ["火", [["烈火飞龙", "召唤一条火龙,造成大量伤害"], ["火焰屏障", "三回合恢复1/5最大生命值,30%控制对方"], ["火焰柱", "抵挡一次伤害,灼烧对方"], ["火焰弹", "50%清除对方能力上升状态"]]],
    ["水", [["深海巨鲨", "造成伤害附加持续伤害"], ["水之守护", "三回合恢复1/5最大生命值,30%控制对方"], ["浪涛汹涌", "三回合免疫控制,获得护盾"], ["水之冲击", "50%清除对方回合类效果"]]],
    ["木", [["唤星者", "恢复全部元素hp和pp,只有一点次数"], ["森林庇护", "四回合吸取对方1/10最大生命值"], ["万物生长", "四回合免疫控制,获得护盾"], ["长者之树", "50%清除并复制对方能力上升状态"]]],
    ["土", [["多尾狐", "召唤一条多尾狐,造成伤害"], ["伫立之墙", "三回合恢复1/5最大生命值和护盾"], ["硬化皮肤", "将护盾转化固定伤害与攻击一并打出"], ["大地之怒", "造成伤害并清除对方护盾"]]],
    ["电", [["十万伏特", "造成伤害附加固定伤害"], ["雷电充能", "二回合恢复1/5最大生命值,回合内必定暴击"], ["闪电之速", "30%降低对方攻击,70%控制对方"], ["雷电千鸟", "50%当对方生命值低于20%斩杀"]]],
]

let bosss1 = [
    {
        id: 1,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 1500,
        energy: 0,
        energyRecover: 100,
        power: 30,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 0,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 2,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 1500,
        energy: 0,
        energyRecover: 100,
        power: 30,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 25,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 3,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 1600,
        energy: 0,
        energyRecover: 100,
        power: 30,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 4,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 1800,
        energy: 0,
        energyRecover: 100,
        power: 30,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 5,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 2000,
        energy: 0,
        energyRecover: 100,
        power: 30,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 6,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 2200,
        energy: 0,
        energyRecover: 100,
        power: 50,
        big: 500,
        speed: 110,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 7,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 2400,
        energy: 0,
        energyRecover: 100,
        power: 50,
        big: 500,
        speed: 120,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 8,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 2600,
        energy: 0,
        energyRecover: 100,
        power: 50,
        big: 500,
        speed: 130,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 9,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 2800,
        energy: 0,
        energyRecover: 100,
        power: 50,
        big: 500,
        speed: 140,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 10,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 3000,
        energy: 0,
        energyRecover: 200,
        power: 50,
        big: 500,
        speed: 150,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 50,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 11,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 3200,
        energy: 0,
        energyRecover: 200,
        power: 50,
        big: 600,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 12,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 3400,
        energy: 0,
        energyRecover: 200,
        power: 50,
        big: 700,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 13,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 3600,
        energy: 0,
        energyRecover: 200,
        power: 50,
        big: 800,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 14,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 3800,
        energy: 0,
        energyRecover: 200,
        power: 50,
        big: 900,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 15,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 4000,
        energy: 0,
        energyRecover: 200,
        power: 50,
        big: 1000,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 16,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 4200,
        energy: 0,
        energyRecover: 100,
        power: 80,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 5,
        criticalDamage: 200,
        dodge: 5,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 17,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 4400,
        energy: 0,
        energyRecover: 100,
        power: 80,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 10,
        criticalDamage: 200,
        dodge: 10,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 18,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 4600,
        energy: 0,
        energyRecover: 100,
        power: 80,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 15,
        criticalDamage: 200,
        dodge: 15,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 19,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 4800,
        energy: 0,
        energyRecover: 100,
        power: 80,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 20,
        criticalDamage: 200,
        dodge: 20,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 20,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 5000,
        energy: 0,
        energyRecover: 100,
        power: 80,
        big: 500,
        speed: 100,
        defense: 0,
        reverse: 0,
        critical: 30,
        criticalDamage: 200,
        dodge: 30,
        seriousInjury: 0,
        recover: 0,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 21,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 5200,
        energy: 0,
        energyRecover: 100,
        power: 100,
        big: 500,
        speed: 100,
        defense: 5,
        reverse: 5,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 22,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 5400,
        energy: 0,
        energyRecover: 100,
        power: 100,
        big: 500,
        speed: 100,
        defense: 10,
        reverse: 10,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 23,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 5600,
        energy: 0,
        energyRecover: 100,
        power: 100,
        big: 500,
        speed: 100,
        defense: 15,
        reverse: 15,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 24,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 5800,
        energy: 0,
        energyRecover: 100,
        power: 100,
        big: 500,
        speed: 100,
        defense: 20,
        reverse: 20,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 25,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 6000,
        energy: 0,
        energyRecover: 100,
        power: 100,
        big: 500,
        speed: 100,
        defense: 30,
        reverse: 30,
        critical: 0,
        criticalDamage: 200,
        dodge: 0,
        seriousInjury: 0,
        recover: 0,
        attack: [1, 1, 1, 1, 1]
    },
    {
        id: 26,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 6200,
        energy: 0,
        energyRecover: 200,
        power: 100,
        big: 500,
        speed: 110,
        defense: 5,
        reverse: 5,
        critical: 5,
        criticalDamage: 200,
        dodge: 5,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1, 1]
    },
    {
        id: 27,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 6400,
        energy: 0,
        energyRecover: 200,
        power: 100,
        big: 500,
        speed: 120,
        defense: 10,
        reverse: 10,
        critical: 10,
        criticalDamage: 200,
        dodge: 10,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1, 1, 1]
    },
    {
        id: 28,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 6600,
        energy: 0,
        energyRecover: 200,
        power: 100,
        big: 500,
        speed: 130,
        defense: 15,
        reverse: 15,
        critical: 15,
        criticalDamage: 200,
        dodge: 15,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1, 1, 1, 1]
    },
    {
        id: 29,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 6800,
        energy: 0,
        energyRecover: 200,
        power: 100,
        big: 500,
        speed: 140,
        defense: 20,
        reverse: 20,
        critical: 20,
        criticalDamage: 200,
        dodge: 20,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1, 1, 1, 1, 1]
    },
    {
        id: 30,
        img: require('@/assets/巨蟹座.jpeg'),
        blood: 7000,
        energy: 0,
        energyRecover: 200,
        power: 100,
        big: 500,
        speed: 150,
        defense: 30,
        reverse: 30,
        critical: 30,
        criticalDamage: 200,
        dodge: 30,
        seriousInjury: 0,
        recover: 100,
        attack: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
    }
]

let artifact1 = [
    {
        name: "金色传说",
        info: "当你获得传说牌,获得100金币",
        price: 50,
        num: 1,
    },
    {
        name: "史诗圣典",
        info: "当你获得史诗牌,获得50金币",
        price: 40,
        num: 1,
    },
    {
        name: "稀有典籍",
        info: "当你获得稀有牌,获得20金币",
        price: 35,
        num: 1,
    },
    {
        name: "龙心",
        info: "获得5力量,250最大生命值,1%最大生命值回血",
        price: 105,
        num: 1,
    },
    {
        name: "饭团",
        info: "获得25回血,75能量恢复",
        price: 75,
        num: 1,
    },
    {
        name: "寿司",
        info: "开局6秒获得1000生命值",
        price: 100,
        num: 1,
    },
    {
        name: "终点线",
        info: "每击败一个敌人获得3%攻击速度",
        price: 50,
        num: 1,
    },
    {
        name: "红包",
        info: "获得25收入",
        price: 95,
        num: 1,
    },
    {
        name: "快餐",
        info: "获得700最大生命值,但获得3重伤",
        price: 50,
        num: 1,
    },
    {
        name: "马",
        info: "获得200最大生命值和一个肌肉",
        price: 70,
        num: 1,
    },
    {
        name: "蝴蝶",
        info: "闪避时获得10治疗",
        price: 30,
        num: 1,
    },
    {
        name: "苹果",
        info: "护甲+8",
        price: 70,
        num: 1,
    },
    {
        name: "唱跳rap篮球",
        info: "篮球+2",
        price: 80,
        num: 1,
    },
    {
        name: "该健身了",
        info: "肌肉+2",
        price: 80,
        num: 1,
    },
    {
        name: "橄榄你",
        info: "橄榄球+2",
        price: 80,
        num: 1,
    },
    {
        name: "冷兵器时代",
        info: "刀叉+2",
        price: 80,
        num: 1,
    },
    {
        name: "真的饿了",
        info: "饿了么+2",
        price: 80,
        num: 1,
    },
    {
        name: "警笛",
        info: "紧急触发线提高15%",
        price: 50,
        num: 1,
    },
    {
        name: "口罩",
        info: "紧急效果提高100%",
        price: 50,
        num: 1,
    },
    {
        name: "UPHONE",
        info: "获得6%暴击和600血量",
        price: 120,
        num: 1,
    },
    {
        name: "小刀",
        info: "获得6%暴击并且大招能够暴击",
        price: 80,
        num: 1,
    },
    {
        name: "笑脸",
        info: "获得4%暴击,闪避,攻速,力量",
        price: 100,
        num: 1,
    },
    {
        name: "鱿鱼",
        info: "击败敌人+4收入",
        price: 80,
        num: 1,
    },
    {
        name: "西兰花",
        info: "失去6重伤",
        price: 80,
        num: 1,
    },
    {
        name: "红辣椒",
        info: "获得25%攻击速度",
        price: 80,
        num: 1,
    },
    {
        name: "筷子",
        info: "获得3重伤,但反伤多造成一次",
        price: 80,
        num: 1,
    },
    {
        name: "钝角",
        info: "获得4闪避,现在可以闪避大招",
        price: 40,
        num: 1,
    },
    {
        name: "法棍",
        info: "刀叉基础伤害提高10",
        price: 40,
        num: 1,
    },
    {
        name: "大蒜",
        info: "每反伤60伤害恢复30血量",
        price: 80,
        num: 1,
    },
    {
        name: "咖啡",
        info: "每6秒触发开局效果(不包括冲锋)",
        price: 60,
        num: 1,
    },
    {
        name: "勇敢的心",
        info: "每恢复到120治疗发射一个刀叉",
        price: 80,
        num: 1,
    },
    {
        name: "仙人掌",
        info: "获得10反伤",
        price: 80,
        num: 1,
    },
    {
        name: "信用卡",
        info: "商店获得5折折扣,但收入-25",
        price: 50,
        num: 1,
    }

]

let card1 = [
    [
        {
            name: "篮球",
            info: "篮球+1(按攻速攻击)",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "肌肉",
            info: "肌肉+1(按攻速加力量)",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "橄榄球",
            info: "橄榄球+1(按攻速减敌人能量)",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "刀叉",
            info: "刀叉+1(按攻速攻击固定40伤害)",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "饿了么",
            info: "饿了么+1(按攻速吸血+3)",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "力量",
            info: "力量+5",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "回血",
            info: "恢复+25",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "闪避",
            info: "闪避+5%",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "暴击",
            info: "暴击+5%",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "尖刺",
            info: "反伤+6",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "收入提升",
            info: "收入+10",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "速度提升",
            info: "攻击速度+10",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "弱化",
            info: "给予对手3重伤",
            color: 'lightgray',
            num: 1,
        },
        {
            name: "能量恢复",
            info: "获得40能量恢复",
            color: 'lightgray',
            num: 1,
        }
    ],
    [
        {
            name: "加油",
            info: "击败敌人加1力量",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "紧急治疗",
            info: "低于50%最大生命值获得50生命恢复",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "战前治疗",
            info: "[冲锋]前3秒获得80生命恢复",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "硬斧",
            info: "刀叉造成额外的100%伤害",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "学习",
            info: "脑子额外摧毁10能量",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "复仇",
            info: "每造成30反伤发射一个刀叉",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "急速开局",
            info: "[冲锋]前3秒获得20的攻击速度",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "贪婪",
            info: "从现在起每花费30金币就获得1力量",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "暴击伤害提升",
            info: "获得额外60%的暴击伤害",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "肌肉记忆",
            info: "每造成120治疗发射一个肌肉",
            color: 'lightblue',
            num: 1,
        },
        {
            name: "蓄能大招",
            info: "使用大招后获得190的能量",
            color: 'lightblue',
            num: 1,
        }
    ],
    [
        {
            name: "硬汉",
            info: "恢复2%最大生命值",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "有氧运动",
            info: "开局每有1力量就加12血量",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "松口气",
            info: "释放大招恢复250生命值",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "自我治疗",
            info: "每秒治疗当前力量的数值",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "硬化石头",
            info: "篮球造成30%额外的力量伤害",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "任务:反伤",
            info: "击败敌人后+1反伤",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "经济学家",
            info: "橄榄球同时造成200%的收入伤害",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "怒气积攒",
            info: "击败敌人后+1暴击率",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "紧急反伤",
            info: "低于50%最大生命值获得15反伤",
            color: 'rgb(193,109,253)',
            num: 1,
        },
        {
            name: "大招伤害",
            info: "获得30%的大招伤害",
            color: 'rgb(193,109,253)',
            num: 1,
        }
    ],
    [
        {
            name: "金币",
            info: "获得400金币",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "带刺护甲",
            info: "获得3护甲和4反伤",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "野兽心态",
            info: "肌肉同时给予1%的攻击速度",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "我是真饿了",
            info: "饿了么吸血效果额外+3",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "扇巴掌",
            info: "战斗开始时造成对手10%最大生命值的伤害",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "明镜止水",
            info: "闪避时发射一个橄榄球",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "打坐",
            info: "击败敌人后+5恢复",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "冲锋强化",
            info: "冲锋能力获得100%提升",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "超长待机",
            info: "冲锋能力获得2秒额外持续时间",
            color: 'rgb(242,175,89)',
            num: 1,
        },
        {
            name: "战前充电",
            info: "开局获得300能量",
            color: 'rgb(242,175,89)',
            num: 1,
        }
    ]
]

let question1 = [
    {
        name: "你最喜欢什么运动?",
        a: "篮球(获得篮球+1和200血量)",
        b: "橄榄球(获得橄榄球+1和200血量)",
    },
    {
        name: "如果你是男同,你是1还是0?",
        a: "1(获得5%暴击)",
        b: "0(获得5%闪避)",
        c: "三明治的馅儿(获得700血量和3重伤)",

    }
]


module.exports = {
    shop1,
    power1,
    thing1,
    mbti1,
    xingzuo1,
    site1,
    say1,
    word1,
    thing2,
    element1,
    bosss1,
    artifact1,
    card1,
    question1
}